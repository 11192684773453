import React, {Component} from "react";
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate
} from "react-router-dom";

import MainPage from "./pages";
import NotFoundPage from "./pages/404";
import CookoffPage from "./pages/cookoff";
import SeasonalEventsPage from "./pages/seasonalEvents";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/cookoff" element={<CookoffPage/>} />
          <Route path="/cookoff/seasonalEvent" element={<SeasonalEventsPage/>} />
          <Route path="/404" element={<NotFoundPage/>} />
        </Routes>
        {/* <Navigate replace to="/404"/> */}
      </Router>
    );
  }
}

export default App;
