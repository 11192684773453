import React, {Component} from "react";

const MainPage = () => {
    return (
        <div>
            <h3>Welcome to GogiiLighthouse</h3>
            <small>Main Page</small>
        </div>
    );
};

export default MainPage;