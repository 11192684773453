import React, {Component} from "react";

const CookoffPage = () => {
    return (
        <div>
            <h2>COOKOFF!</h2>
        </div>
    );
};

export default CookoffPage;