import React, {Component} from "react";

const SeasonalEventsPage = () => {
    return (
        <div>
            <h2>SeasonalEvents!</h2>
        </div>
    );
};

export default SeasonalEventsPage;